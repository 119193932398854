import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { MdOutlineClose } from "react-icons/md";
import { TbShoppingBagX } from "react-icons/tb";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "config";
import { CartContext } from "context/CartContext";
import { priceComma } from "utils/priceComma";
import { useTranslation } from "react-i18next";
import ProductCard from "./ProductCard";
import { httpReauest } from "api/api";
import Loading from "./Loading";

const SearchModal = ({ setModal }) => {
  const [data, setData] = useState([]);
  const [text, settext] = useState();
  const [loading, setloading] = useState();

  const [t] = useTranslation("global");

  async function getData(params) {
    setloading(true);
    const res = await httpReauest(
      "GET",
      "/product?limit=10&search_key=" + text,
      {},
      {}
    );
    setData(res.data.data);
    setloading(false);
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      getData();
    }, 2000);

    return () => clearTimeout(timeout);
  }, [text]);

  return (
    <div className="w-full relative  ">
      <div className="flex justify-between w-full items-center border-b py-3 px-3 mb-6">
        <button
          onClick={() => {
            setModal(false);
          }}
          className="flex items-center "
        >
          <MdOutlineClose size={25} />
        </button>
        <span className="text-xl ">{t("search")}</span>
      </div>
      <div className="px-3">
        <input
          className="w-full form-control max-w-[480px] mx-auto"
          onChange={(e) => settext(e.target.value)}
          placeholder={t("find-product")}
        />
        <div className="mt-3  gap-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 overflow-y-scroll max-h-[82vh]">
          {loading ? (
            <Loading />
          ) : data?.length ? (
            data?.map((item) => <ProductCard data={item} t={t} />)
          ) : (
            <h5 className="text-center col-span-full">{t("not-found")}</h5>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
