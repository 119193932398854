import React from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

const AboutSection = () => {
  const [t] = useTranslation("global");
  return (
    <section
      style={{ backgroundImage: "url(/hznosaje6zy5f0ckg7au.jpg)" }}
      class="bg-right lg:bg-center bg-cover "
    >
      <div class=" bg-black/60  py-20 ">
        <div class="  lg:mx-[100px] mx-6 ">
          <div class="felx order-2 lg:order-1 flex-col h-full justify-center items-center text-white container text-center">
            <h3 class="text-white text-[2rem] font-bold mb-3">{t("logo")}</h3>
            <p class="">
             {t("slider-desc")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
