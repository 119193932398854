import About from "components/About";
import AboutSection from "components/AboutSection";
import AboutSection2 from "components/AboutSection2";
import Gallery from "components/common/Gallery";
import Footer from "components/Footer";
import Header from "components/header";
import PathShow from "components/PathShow";
import WhySection from "components/WhySection";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const [t] = useTranslation("global");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"EMPORIUM POINT | سبد خرید"}</title>
        <meta name="og:title" content={"EMPORIUM POINT | سبد خرید"} />
      </Helmet>
      <div className="font-[Yekan] mt-[110px] md:mt-[83px]">
        <Header />
        <AboutSection />
        {/* <TeamComponent /> */}
        {/* <Banners/> */}
        <div className="my-10 container">
          <WhySection />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AboutPage;
