import React from "react";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ProductCard from "./common/ProductCard";

const Slider = ({ bgcolor, img, color, h1, desc, data }) => {
  const [t] = useTranslation("global");
  return (
    <>
      <div style={{ background: bgcolor }} class={`p-0]`}>
        <div className="container-xxl ">
          <div className="pt-10 pb-20 flex flex-col justify-center items-center gap-3">
            {h1 ? (
              <>
                <h2
                  style={{ color: color }}
                  className=" text-[28px] lg:text-[32px] font-bold  text-center"
                >
                  {h1}
                </h2>
                <p
                  style={{ color: color }}
                  className="text-[16px] lg:text-[17px] text-center"
                >
                  {desc}
                </p>
              </>
            ) : (
              <>
                <h1 className=" text-[28px] lg:text-[32px] font-bold  text-center">
                  {t("logo")}
                </h1>
                <p className="text-[16px] lg:text-[17px] text-center">
                  {t("slider-desc")}
                </p>
              </>
            )}

            <img
              src={img}
              className="max-w-[450px] w-full mx-auto"
              alt="Mobile"
            />
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container-xxl relative -top-12">
          <Swiper
            modules={[Autoplay]}
            autoplay={{ delay: 3000 }}
            loop
            dir="ltr"
            spaceBetween={10}
            breakpoints={{
              100: { slidesPerView: 2 },
              617: { slidesPerView: 3 },
              1024: { slidesPerView: 4 },
              1224: { slidesPerView: 5 },
            }}
          >
            {data.map((item) => (
                <SwiperSlide>
                  <ProductCard data={item} t={t} />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Slider;
