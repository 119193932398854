import React, { useContext, useEffect, useState } from "react";

import { FaBox, FaGift } from "react-icons/fa6";
import CheckoutForm from "../components/CheckoutForm";
import { CartContext } from "../context/CartContext";

import CheckoutPrice from "../components/CheckoutPrice";
import { Helmet } from "react-helmet";
import Header from "components/header";
import Footer from "components/Footer";

const CheckoutPage = () => {
  const [loading, setloading] = useState();
  const [productData, setproductData] = useState();

  const { cart, setCart } = useContext(CartContext);
  const total = cart.reduce((accumulator, current) => {
    return accumulator + current.price * current.count;
  }, 0);
  // if (loading) {
  //   return <Loading />;
  // }
  return (
    <>
      <Helmet>
        <title>{"EMPORIUM POINT | تسویه حساب"}</title>
        <meta name="og:title" content={"EMPORIUM POINT | تسویه حساب"} />
      </Helmet>
      <div className="font-[Yekan] mt-[110px] md:mt-[83px]">
        <Header />
        <div className=" overflow-hidden  py-10  bg-white text-right">
          <div class="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
            <div class="px-4 ">
              <CheckoutPrice checkoutPage total={total} />
            </div>

            <CheckoutForm total={total} />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CheckoutPage;
