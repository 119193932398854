import React from "react";
import { useTranslation } from "react-i18next";

const WhySection = () => {
  const [t] = useTranslation("global");

  return (
    <section className="  mb-10 lg:px-10 px-2">
      <div className=" ">
        <h3 className=" text-[1.75rem]">{t("why-logo")}</h3>
      </div>
      <div className="">
        <div className="grid grid-cols-1 lg:grid-cols-2  gap-3 mt-1">
          <div className="flex justify-start items-start gap-4 w-full mt-5">
            <div className="border shadow   rounded">
              <img
                className="lg:max-w-[100px] max-w-[60px] lg:p-4"
                src="/images/express.png"
                alt=""
              />
            </div>
            <div className="flex flex-col items-start col-span-3">
              <h3 className="font-bold lg:text-[18px] text-[14px]">
                {t("fast-travel")}
              </h3>
              <p className="lg:text-[16px] text-[12px] ">
                {t("fast-travel-desc")}
              </p>
            </div>
          </div>
          <div className="flex justify-start items-start gap-4 w-full mt-5">
            <div className="border shadow   rounded">
              <img
                className="lg:max-w-[100px] max-w-[60px]  lg:p-4"
                src="/images/24-hours.png"
                alt=""
              />
            </div>
            <div className="flex flex-col items-start col-span-3">
              <h3 className="font-bold lg:text-[18px] text-[14px]">
                {t("24-hour")}
              </h3>
              <p className="lg:text-[16px] text-[12px]">{t("24-hour-desc")}</p>
            </div>
          </div>
          <div className="flex justify-start items-start gap-4 w-full mt-5">
            <div className="border shadow   rounded">
              <img
                className="lg:max-w-[100px] max-w-[60px] lg:p-4"
                src="/images/free-delivery.png"
                alt=""
              />
            </div>
            <div className="flex flex-col items-start col-span-3">
              <h3 className="font-bold lg:text-[18px] text-[14px]">
                {t("free-travel")}
              </h3>
              <p className="lg:text-[16px] text-[12px]">
                {t("free-travel-desc")}
              </p>
            </div>
          </div>
          <div className="flex justify-start items-start gap-4 w-full mt-5">
            <div className="border shadow   rounded">
              <img
                className="lg:max-w-[100px] max-w-[60px] lg:p-4"
                src="/images/online-payment.png"
                alt=""
              />
            </div>
            <div className="flex flex-col items-start col-span-3">
              <h3 className="font-bold lg:text-[18px] text-[14px]">
                {t("online-pay")}
              </h3>
              <p className="lg:text-[16px] text-[12px]">
                {t("online-pay-desc")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhySection;
