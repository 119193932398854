import React, { useState } from "react";
import ReactImageGallery from "react-image-gallery";
import ReactImageMagnify from "@blacklab/react-image-magnify";
import "react-image-gallery/styles/css/image-gallery.css";
import { CDN_BASE_URL } from "../config";

const ProductGallery = ({ images }) => {
  const ImageGallery = [];
  images?.map((item) =>
    ImageGallery.push({
      original: CDN_BASE_URL + item,
      thumbnail: CDN_BASE_URL + item,
    })
  );

  return (
    <div className=" relative lg:p-8">
      {ImageGallery.length > 1 && <ReactImageGallery items={ImageGallery} />}
    </div>
  );
};

export default ProductGallery;
