import React, { useRef, useState } from "react";
import { FaAngleDown, FaWhatsapp } from "react-icons/fa6";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { httpReauest } from "api/api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
const VITE_SITE_KEY = "6LchGzQqAAAAAACh11TFb0yF1BUb3nQAZMNZUPJD";
const SITE_SECRET = "6LchGzQqAAAAAM1RpZ-oQwac9Bas5x6L19KvPyHL";
const Contact = ({ aboutref }) => {
  const recaptcha = useRef(null);

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      phone: Yup.string()
        .min(11, "شماره موبایل صحیح نیست")
        .max(11, "شماره موبایل صحیح نیست")
        .required("الزامی است"),
    });
  };

  const formik = useFormik({
    validationSchema: VALUE_SIGN_VALIDATION,
    initialValues: { name: "", phone: "", budget: "", target: "" },
    onSubmit: async (values) => {
      if (recaptcha?.current.getValue() == "") {
        toast.error("من ربات نیستم را تکمیل کنید");
      } else {
        await httpReauest("POST", "/send", values, {}).then((res) => {
          toast.success("اطلاعات ارسال شد");
          formik.resetForm();
        });
      }
    },
  });

  const [t] = useTranslation("global");

  return (
    <div class="container-xxl py-5">
      <div class="container">
        <div class="bg-light rounded p-3">
          <div
            class="bg-white rounded p-4"
            style={{ border: "1px dashed rgba(0, 185, 142, .3)" }}
          >
            <div class="row g-5 align-items-center">
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <img
                  class="img-fluid rounded w-100"
                  src="/location (3).jpg"
                  alt=""
                />
              </div>
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <div class="mb-4">
                  <h2 class="mb-3 font-bold text-[24px]">
                    {t("menu-contact")}
                  </h2>
                  <p>{t("contact-desc")}</p>
                </div>
                <Link
                  to=""
                  class="btn btn-primary py-3 px-4 mx-2 w-full lg:w-fit mb-1"
                >
                  <i class="fa fa-phone-alt me-2"></i>
                  {t("call")}
                </Link>
                <Link
                  to="/request"
                  class="btn btn-secondary py-3 px-4 w-full lg:w-fit mb-1"
                >
                  <i class="fa fa-calendar-alt me-2"></i>
                  {t("menu-button")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
