import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PathShow = ({ category, sub }) => {
  const [t] = useTranslation("global");
  return (
    <div class="container-fluid  bg-[#f5f5f7] p-2">
      <div class=" container-xxl">
        <div className="flex gap-2">
          <Link to={"/"}>{t("menu-home")}</Link>
          <span>/</span>
          <Link to={"/category/" + category}>{category}</Link>
          {sub && (
            <>
              <span>/</span>
              <Link to={"/category/" + category + "/" + sub}>{sub}</Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PathShow;
