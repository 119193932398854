import Header from "components/header";
import "../App.css";
import Slider from "components/Slider";
import About from "components/About";
import WhyusSection from "components/WhyusSection";
import Services from "components/Services";
import Example from "components/Example";
import Testmonials from "components/Testmonials";
import Team from "components/Team";
import Pricing from "components/Pricing";
import FaqSection from "components/FaqSection";
import Contact from "components/Contact";
import Footer from "components/Footer";
import Projects from "components/Projects";
import { FaWhatsapp } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { httpReauest } from "api/api";

function Home() {
  const [t] = useTranslation("global");
  const [iphones, setiphones] = useState([]);
  const [macks, setmacks] = useState([]);
  const [ipads, setipads] = useState([]);
  const [accessories, setaccessories] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData(params) {
    const res = await httpReauest("GET", "/product?category=iphone", {}, {});
    setiphones(res.data.data);
    const res2 = await httpReauest("GET", "/product?category=mac", {}, {});
    setmacks(res2.data.data);
    const res3 = await httpReauest("GET", "/product?category=ipad", {}, {});
    setipads(res3.data.data);
    const res4 = await httpReauest("GET", "/product?category=accessories&sort=createdAt", {}, {});
    setaccessories(res4.data.data);
  }

  return (
    <>
      <div className=" mt-[110px] md:mt-[83px]">
        <Header />
        <Slider data={iphones} bgcolor="#f5f5f7" img="/first.png" />
        <Slider
          data={macks}
          bgcolor="#000"
          img="/hero_iphone16pro_avail__fnf0f9x70jiy_largetall_2x.jpg"
          color="#fff"
          h1={t("classic")}
          desc={t("order-now")}
        />
        <Slider
          bgcolor="#000"
          data={ipads}
          img="/iPad-Pro.png"
          color="#fff"
          h1={t("classic")}
          desc={t("order-now")}
        />

        <div className="relative">
          <h3 className="absolute left-1/2 top-[40px] w-full lg:w-fit lg:top-[8%] -translate-x-1/2 -translate-y-1/2 py-2 px-8 font-bold text-center lg:rounded-[8px] bg-[#000]/70 text-white text-[22px] lg:text-[30px]">
            دفتر کاری ما رو لورم اپیسوم
          </h3>
          <p className="absolute left-1/2 top-[85px] w-full lg:w-fit lg:top-[13%] -translate-x-1/2 -translate-y-1/2 py-2 px-8 lg:rounded-[8px] text-center bg-[#fff]/70 text-black text-[14px] lg:text-[18px]">
            آدرس محل دفتر کار باید در اینجا قرار بگیرد
          </p>
          <img src="/office.jpg" className="w-full" />
        </div>
        <Slider
          bgcolor="#f5f5f7"
          data={accessories}
          img="/apple_watch_series_10.png"
          color="#000"
          h1={t("classic")}
          desc={t("order-now")}
        />

        <Footer />
      </div>
    </>
  );
}

export default Home;
