import Home from "pages/Home";
import "./App.css";
import {
  createBrowserRouter,
  Link,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import ProjectsPage from "pages/ProjectsPage";
import ProjectsCompanyPage from "pages/ProjectsCompanyPage";
import { useEffect, useState } from "react";
import { FaPhone, FaWhatsapp } from "react-icons/fa6";
import ProjectDetailPage from "pages/ProjectDetailPage";
import ScrollToTop from "components/ScrooltoTop";
import AboutPage from "pages/AboutPage";
import Request from "pages/Request";
import i18next from "i18next";
import global_en from "./translation/en/global.json";
import global_fa from "./translation/fa/global.json";
import { I18nextProvider, useTranslation } from "react-i18next";
import MapPage from "pages/MapPage";
import ContactPage from "pages/ContactPage";
import CategoryPage from "pages/CategoryPage";
import SubCategoryPage from "pages/SubCategoryPage";
import ProductDetailsPage from "pages/ProductDetailsPage";
import { CartContext } from "context/CartContext";
import CartPage from "pages/CartPage";
import CheckoutPage from "pages/CheckoutPage";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: { global: global_en },
    fa: { global: global_fa },
  },
});

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <ScrollToTop />
          <Home />
        </>
      ),
    },
    {
      path: "/category/:name",
      element: (
        <>
          <ScrollToTop />
          <CategoryPage />
        </>
      ),
    },
    {
      path: "/category/:name/:sub",
      element: (
        <>
          <ScrollToTop />
          <SubCategoryPage />
        </>
      ),
    },
    {
      path: "/product/:name",
      element: (
        <>
          <ScrollToTop />
          <ProductDetailsPage />
        </>
      ),
    },

    {
      path: "/contact",
      element: (
        <>
          <ScrollToTop />
          <ContactPage />
        </>
      ),
    },
    {
      path: "/cart",
      element: (
        <>
          <ScrollToTop />
          <CartPage />
        </>
      ),
    },
    {
      path: "/checkout",
      element: (
        <>
          <ScrollToTop />
          <CheckoutPage />
        </>
      ),
    },
    {
      path: "/about",
      element: (
        <>
          <ScrollToTop />
          <AboutPage />
        </>
      ),
    },
  ]);

  const [cart, setCart] = useState([]);
  const [cartshow, setCartshow] = useState(false);

  return (
    <>
      <I18nextProvider i18n={i18next}>
        <CartContext.Provider value={{ cart, setCart, cartshow, setCartshow }}>
          <RouterProvider router={router} />
        </CartContext.Provider>
      </I18nextProvider>
    </>
  );
}

export default App;
