import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { httpReauest } from "../utils/httpRequest";
import ProductCard from "./common/ProductCard";
import { useTranslation } from "react-i18next";

const SliderSimilier = ({ category }) => {
  const [productId, setProductId] = useState([]);
  const [loading, setloading] = useState(false);

  async function getId() {
    setloading(true);
    const response = await httpReauest(
      "GET",
      `/product?category=${category}`,
      {},
      {}
    );
    setProductId(response.data.data);
    setloading(false);
  }

  useEffect(() => {
    getId();
  }, []);

  const [t] = useTranslation("global");

  return (
    <>
      <div className="container-xxl">
        <Swiper
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            800: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            1100: {
              slidesPerView: 4,
              spaceBetween: 15,
            },
            1400: {
              slidesPerView: 5,
              spaceBetween: 15,
            },
          }}
          pagination={{
            clickable: true,
          }}
        >
          {productId?.map((product, idx) => (
              <SwiperSlide className="pb-10 pt-6">
                <ProductCard white data={product} key={idx} t={t} />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </>
  );
};

export default SliderSimilier;
