import { CDN_BASE_URL } from "config";
import React from "react";
import { FaBed, FaMapLocation, FaUser } from "react-icons/fa6";
import { Link } from "react-router-dom";

const CategoryCard = ({ data, t , prev}) => {
  return (
    <Link
      to={`/category/${prev}/${data?.url}`}
      className="block w-[110px] md:w-[150px]"
    >
      <div className="flex flex-col gap-2  border  rounded-[12px] ">
        <img
          src={CDN_BASE_URL + data?.img }
          className="w-full object-contain p-2 "
          alt="Product"
        />
      </div>
      <h3 className="font-bold text-[16px] lg:text-[18px] mt-3 text-center text-black">
        {data?.name}
      </h3>
    </Link>
  );
};

export default CategoryCard;
