import React, { useRef, useState } from "react";
import { FaAngleDown, FaEnvelope, FaWhatsapp } from "react-icons/fa6";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { httpReauest } from "api/api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
const ContactForm = ({ aboutref, noname }) => {
  const navigate = useNavigate();
  const [t] = useTranslation("global");

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required(t("required")),
      content: Yup.string().required(t("required")),
      phone: Yup.string()
        .min(11, "شماره موبایل صحیح نیست")
        .max(11, "شماره موبایل صحیح نیست")
        .required(t("required")),
    });
  };

  const formik = useFormik({
    validationSchema: VALUE_SIGN_VALIDATION,
    initialValues: { name: "", phone: "", content: "" },
    onSubmit: async (values) => {
      await httpReauest("POST", "/send", values, {}).then((res) => {
        toast.success("اطلاعات ارسال شد");
        navigate("/projects");
        formik.resetForm();
      });
    },
  });

  return (
    <section
      id="contact"
      class="contact bg-white section  rounded shadow py-10"
    >
      <div class="container" data-aos="fade-up" data-aos-delay="100">
        {!noname && (
          <div
            dir="rtl"
            class="info-wrap"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="row gy-5">
              <div class="col-lg-4">
                <Link
                  target="_blank"
                  to={"https://wa.me/989377547384"}
                  class="info-item d-flex align-items-center"
                >
                  <i className="flex-shrink-0">
                    <FaWhatsapp size={35} className="flex-shrink-0" />
                  </i>
                  <div>
                    <h3>Whatsapp</h3>
                    <p dir="ltr">0937 754 7384</p>
                  </div>
                </Link>
              </div>

              <Link to={"tel:+989377547384"} class="col-lg-4">
                <div class="info-item d-flex align-items-center">
                  <i class="bi bi-telephone flex-shrink-0"></i>
                  <div>
                    <h3>Call </h3>
                    <p dir="ltr">0937 754 7384</p>
                  </div>
                </div>
              </Link>

              <div class="col-lg-4">
                <div class="info-item d-flex align-items-center">
                  <i class="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h3>Email</h3>
                    <p>info@example.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <form
          class="php-email-form"
          data-aos="fade-up"
          data-aos-delay="300"
          dir="rtl"
          onSubmit={formik.handleSubmit}
        >
          <div class="row gy-4 mb-4">
            <div class="col-md-6">
              <input
                type="text"
                name="name"
                id="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                class="form-control"
                placeholder={t("form-name")}
              />
              {formik.errors.name && formik.touched.name && (
                <small className="text-danger" style={{ color: "red" }}>
                  {formik.errors.name}
                </small>
              )}
            </div>

            <div class="col-md-6 ">
              <input
                type="text"
                class="form-control"
                name="phone"
                id="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                placeholder={t("form-phone")}
                required=""
              />
              {formik.errors.phone && formik.touched.phone && (
                <small className="text-danger" style={{ color: "red" }}>
                  {formik.errors.phone}
                </small>
              )}
            </div>

            <div class="col-md-12 ">
              <textarea
                name="content"
                id="content"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.content}
                class="form-control"
                placeholder={t("form-text")}
              />
              {formik.errors.content && formik.touched.content && (
                <small className="text-danger" style={{ color: "red" }}>
                  {formik.errors.content}
                </small>
              )}
            </div>

            <div class="col-md-12 text-center">
              <button className="btn btn-primary w-fit py-2" type="submit">
                {t("submit")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
