import React from "react";
import { useContext } from "react";
import { MdOutlineClose } from "react-icons/md";
import { TbShoppingBagX } from "react-icons/tb";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "config";
import { CartContext } from "context/CartContext";
import { priceComma } from "utils/priceComma";
import { useTranslation } from "react-i18next";

const CartModal = ({ setModal }) => {
  const { cart, setCart } = useContext(CartContext);

  const [t] = useTranslation("global");

  function handleDelete(e) {
    const newCart = cart.filter((c) => c.id !== e.id);

    setCart(newCart);
  }

  return (
    <div className="w-full relative flex flex-col items-center justify-between ">
      <div className="flex justify-between w-full items-center border-b py-3 px-3 mb-6">
        <button
          onClick={() => {
            setModal(false);
          }}
          className="flex items-center "
        >
          <MdOutlineClose size={25} />
        </button>
        <span className="text-xl ">{t("cart")}</span>
      </div>
      {cart.length ? (
        <div className="flex flex-col w-full">
          {cart.map((e) => (
            <div
              dir="rtl"
              className="flex relative w-full gap-3 justify-start items-start border-b p-3"
            >
              <img className="w-[70px]" src={CDN_BASE_URL + e.img} alt="" />
              <div className="flex flex-col text-[12px]">
                <span className="font-bold">{e.name}</span>
                <span>
                  <span>{e.color}</span>
                  <span>{e.size}</span>
                </span>
                <span>{e.count}x</span>
                <span>{priceComma(e.price, 2)}تومان</span>
              </div>
              <button
                onClick={() => handleDelete(e)}
                className="absolute top-1 left-1"
              >
                <MdOutlineClose size={20} color={"#999"} />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <>
          <TbShoppingBagX size={130} color={"#8f8f8f"} />
          <p>{t("cart-is-empty")}</p>
        </>
      )}
      <Link
        onClick={() => setModal(false)}
        to={"/cart"}
        className="mt-[0.7rem] w-[90%] text-black rounded-3xl text-center py-3 bg-white "
      >
        {t("see-cart")}
      </Link>
      <Link
        onClick={() => setModal(false)}
        to={"/checkout"}
        className="mt-[0.7rem] w-[90%] text-white rounded-3xl text-center py-3 bg-slate-800 "
      >
        {t("checkout")}
      </Link>
    </div>
  );
};

export default CartModal;
